import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  description: 'Reumatologista em Maringá',
  title: 'Dra. Sueny Munarin',
  url: 'reumatologiamaringa.com.br',
  keywords: [
    'reumatologia',
    'maringá',
    'maringa',
    'maringaparana',
    'reumatologista',
    'reumato',
    'sueny',
    'monarin',
    'munarin',
    'sanches',
    'médica',
    'medica',
    'médico',
    'medico',
    'reumatismo',
    'dor',
    'costas',
    'mão',
    'braço',
    'junta',
    'juntas',
    'osteoporose',
    'idoso',
    'fibromialgia',
    'artrose',
    'lupus',
    'doença',
    'autoimune',
  ],
};

const SEO = () => {
  return (
    <Helmet>
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <title>{SEO_DATA.title}</title>
    </Helmet>
  );
};

export default SEO;
